import * as THREE from 'three';
import gsap from 'gsap';
import Time from './Utils/Time.js';
import Sizes from './Utils/Sizes.js';
import Stats from './Utils/Stats.js';

import Resources from './Resources.js';
import Renderer from './Renderer.js';
import Camera from './Camera.js';
import World from './World.js';

import assets from './assets.js';
import Touch from './touch.js';

export default class Experience {
  // static instance;

  constructor(_options = {}) {
    if (Experience.instance) {
      return Experience.instance;
    }
    Experience.instance = this;

    // Options
    this.targetElement = _options.targetElement;

    if (!this.targetElement) {
      console.warn("Missing 'targetElement' property");
      return;
    }

    this.time = new Time();
    this.sizes = new Sizes();
    this.setConfig();
    this.setStats();
    this.setScene();
    this.setCamera();
    this.setRenderer();
    this.setResources();
    this.setWorld();

    this.sizes.on('resize', () => {
      this.resize();
    });

    this.update();

    //Camera Stuff
    this.loaded = false;
    this.cameraCoords = { x: 30, y: 150, z: 30 };
    this.camera.instance.position.set(30, 150, 30);
    this.cameraFollow();
    // this.camera.instance.quaternion.set(
    //   -0.2706115851600799,
    //   0.6532743794818335,
    //   0.2706100886698562,
    //   0.6532779921288233
    // );

    this.cameraPosition = 'top';
    this.camera.instance.quaternion.set(
      -0.19696916101934828,
      0.6791181468372861,
      0.19696982766009027,
      0.679120445307884
    );

    this.resources.on('groupEnd', (_group) => {
      this.resize();
    });
  }

  cameraFollow() {
    this.time.on('tick', () => {
      if (this.loaded) {
        const characterDetails = this.world.char;
        if (characterDetails) {
          if (
            characterDetails.model.position.x < -42 &&
            characterDetails.model.position.x > -75 &&
            characterDetails.model.position.z > 10 &&
            characterDetails.model.position.z < 40
          ) {
            if (this.cameraPosition === 'top') {
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                x: -3.9796843693760565e-7,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                y: 0.7071068019423119,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                z: 3.9796846030093416e-7,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                w: 0.7071067604305586,
              });
              this.cameraPosition = 'bottom';
            }

            gsap.to(this.cameraCoords, {
              duration: 0.1,
              x: characterDetails.model.position.x + 30,
            });
            gsap.to(this.cameraCoords, {
              duration: 3,
              y: characterDetails.model.position.y + 2,
            });
            gsap.to(this.cameraCoords, {
              duration: 0.1,
              z: characterDetails.model.position.z,
            });
          } else {
            if (this.cameraPosition === 'bottom') {
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                x: -0.19696916101934828,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                y: 0.6791181468372861,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                z: 0.19696982766009027,
              });
              gsap.to(this.camera.instance.quaternion, {
                duration: 3,
                w: 0.679120445307884,
              });
              this.cameraPosition = 'top';
            }
            gsap.to(this.cameraCoords, {
              duration: 0.1,
              x: characterDetails.model.position.x + 30,
            });
            gsap.to(this.cameraCoords, {
              duration: 4,
              y: characterDetails.model.position.y + 20,
            });
            gsap.to(this.cameraCoords, {
              duration: 0.1,
              z: characterDetails.model.position.z,
            });
          }
        }

        this.camera.instance.position.set(
          this.cameraCoords.x,
          this.cameraCoords.y,
          this.cameraCoords.z
        );
      }
    });
  }

  setConfig() {
    this.config = {};

    // Debug
    // this.config.debug = window.location.hash === "#debug";
    this.config.debug = true;

    // Pixel ratio
    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

    // Width and height
    const boundings = this.targetElement.getBoundingClientRect();
    this.config.width = boundings.width;
    this.config.height = boundings.height || window.innerHeight;
  }

  setStats() {
    if (this.config.debug) {
      this.stats = new Stats(true);
    }
  }

  setScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#2B2B2B');
  }

  setCamera() {
    this.camera = new Camera();
  }

  setRenderer() {
    this.renderer = new Renderer({ rendererInstance: this.rendererInstance });

    this.targetElement.appendChild(this.renderer.instance.domElement);
  }

  setResources() {
    this.resources = new Resources(assets);
  }

  setWorld() {
    this.world = new World();
  }

  setTouch() {
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.touch = new Touch();
    } else {
      this.touch = false;
    }
  }

  update() {
    if (this.stats) this.stats.update();

    this.camera.update();

    if (this.world) this.world.update();

    if (this.renderer) this.renderer.update();

    window.requestAnimationFrame(() => {
      this.update();
    });
  }

  resize() {
    // Config
    const boundings = this.targetElement.getBoundingClientRect();
    this.config.width = boundings.width;
    this.config.height = boundings.height;

    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

    if (this.camera) this.camera.resize();

    if (this.renderer) this.renderer.resize();

    if (this.world) this.world.resize();
  }

  destroy() {}
}
